import React, { useEffect } from 'react'
import { makeStyles, Button, Icon, Slide, Grid, Paper, Typography, Box } from '@material-ui/core'

import Bg from 'assets/bg.png'
import Logo from 'assets/logo.png'
import Error from 'assets/error.png'

const Component: React.FC = () => {
  const classes = useStyles()

  useEffect(() => {
    document.title = 'Oops'

    return () => {}
  }, [])

  return (
    <div className={classes.root}>
      <div className={classes.layout}>
        <Slide in={true} direction='up'>
          <Box sx={{ flexGrow: 1 }}>
            <Grid container>
              <Grid item xs={12} sm={3} xl={3}></Grid>
              <Grid item xs={12} sm={6} xl={6}>
                <Paper className={classes.paper}>
                  <img src={Logo} alt='logo' style={{ display: 'block', marginLeft: 'auto', marginRight: 'auto' }} className={classes.logo} />
                  <img
                    src={Error}
                    alt='error'
                    style={{ display: 'block', marginLeft: 'auto', marginRight: 'auto', maxWidth: '50%', maxHeight: '50%' }}
                  />
                  <Typography variant='h4' gutterBottom align='center'>
                    Oooops! Um erro ocorreu
                  </Typography>
                  <Button
                    className={classes.button}
                    variant='contained'
                    color='primary'
                    startIcon={<Icon>arrow_back</Icon>}
                    onClick={async () => location.assign('/')}
                    fullWidth>
                    Voltar para home
                  </Button>
                </Paper>
              </Grid>
              <Grid item xs={12} sm={3} xl={3}></Grid>
            </Grid>
          </Box>
        </Slide>
      </div>
    </div>
  )
}

const useStyles = makeStyles(theme => ({
  layout: {
    width: '90%',
    height: '100%',
    marginLeft: 'auto',
    marginRight: 'auto',
    [theme.breakpoints.down('xl')]: {
      width: '80%',
    },
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
    padding: theme.spacing(1),
  },
  logo: {
    marginTop: theme.spacing(2),
  },
  paper: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    padding: theme.spacing(2),
  },
  button: {
    marginTop: theme.spacing(2),
  },
  root: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
    backgroundImage: `url(${Bg})`,
    backgroundPosition: 'right top',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
  },
}))

export default Component
