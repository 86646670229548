import React from 'react'
import { makeStyles, Slide, Button } from '@material-ui/core'
import { Alert } from '@material-ui/lab'

import Logo from 'assets/logo.png'
import Bg from 'assets/bg.png'

const Component: React.FC = () => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <div className={classes.layout}>
        <Slide in={true} direction='up'>
          <img src={Logo} alt='logo' style={{ marginBottom: 10 }} className={classes.logo} />
        </Slide>

        <Slide in={true} direction='up'>
          <Alert variant='filled' severity='warning'>
            Página não localizada{' '}
            <Button size='small' variant='contained' color='primary' onClick={() => window.open('/#', '_self')} className={classes.button}>
              CLIQUE AQUI
            </Button>{' '}
            para acessar a tela de pesquisa
          </Alert>
        </Slide>
      </div>
    </div>
  )
}

const useStyles = makeStyles(theme => ({
  layout: {
    width: '90%',
    height: '100%',
    marginLeft: 'auto',
    marginRight: 'auto',
    [theme.breakpoints.down('xl')]: {
      width: '80%',
    },
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
    padding: theme.spacing(1),
  },
  logo: {
    marginTop: theme.spacing(2),
  },
  root: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
    backgroundImage: `url(${Bg})`,
    backgroundPosition: 'right top',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
  },
  button: {
    height: 24,
    marginBottom: 3,
  },
}))

export default Component
